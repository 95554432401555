import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAccounts } from '../../store/features/accounts/accountSlice'
import moment from 'moment'
import DataTable from 'react-data-table-component';
// import { axiosRequest } from '../../services/axiosRequest'
import axios from 'axios';
import { baseUrl } from '../../services/api';
// import { alert } from '@material-tailwind/react';

const MyDataTable = () => {
    const dispatch = useDispatch();
    const accounts = useSelector(state => state.accounts.accounts);
    const [searchEmail, setSearchEmail] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [individualUser, setIndividualUser]=useState([]);
    const [showIndividualUser, setShowIndividualUser] = useState(false)
  
    useEffect(() => {
      dispatch(fetchAccounts());
    }, [dispatch]);
  
    useEffect(() => {
      if (accounts?.data) {
        setFilteredAccounts(
          accounts.data.filter(account =>
            account.email.toLowerCase().includes(searchEmail.toLowerCase())
          )
        );
      }
    }, [searchEmail, accounts]);
  
    function dateFormat(date) {
      return moment(date).format('l');
    }
  
    const columns = [
      {
        name: '#',
        selector: (row, index) => index + 1,
        cell: (row, index) => <div className="text-gray-800 font-medium">{index + 1}</div>,
        width: '50px',
        center: true,
      },
      {
        name: 'ID',
        selector: row => row.user_id,
        sortable: true,
        width: '70px',
        center: true,
      },
      {
        name: 'Icon',
        selector: row => row.icon,
        sortable: false,
        cell: row => (
          <div>
            <div className="h-5 w-5 flex-shrink-0">
              <img className="h-full w-full" src="https://myridex.com/wp-content/themes/myridex-theme/assets/img/ridexLog1.png" alt="" />
            </div>
          </div>
        ),
        width: '60px',
        center: true,
      },
      {
        name: 'Full Name',
        selector: row => `${row.first_name} ${row.last_name}`,
        sortable: true,
        width: '170px',
      },
      {
        name: 'Contact',
        selector: row => row.phone_number,
        sortable: true,
        width: '120px',
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
      },
      {
        name: 'User Role',
        selector: row => (row.role_id === 1 ? 'Customer' : 'Provider'),
        sortable: true,
        width: '120px',
        center: true,
      },
      {
        name: 'Created at',
        selector: row => dateFormat(row.created_at),
        sortable: true,
        width: '100px',
      },
      {
        name: 'Status',
        selector: row => (row.user_status === 1 ? 'Active' : 'No'),
        sortable: true,
        width: '90px',
      },
      {
        name: 'Edit',
        cell: row => (
          <button className="bg-blue-500 text-white px-4 py-1 rounded"
          onClick={()=>{
            console.log(JSON.stringify(row))
            setIndividualUser(row);
            setShowIndividualUser(true);
          }}
          >Edit</button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: '60px',
      },
    ];
    const customStyles = {
        headCells: {
          style: {
            backgroundColor: '#1E3A8A', // Tailwind CSS's 'bg-blue-800'
            color: '#FFFFFF', // Tailwind CSS's 'text-white'
          },
        },
        rows: {
            style: {
              minHeight: '60px', // override the row height (default is 48px)
              paddingTop: '8px', // add padding to top
              paddingBottom: '8px', // add padding to bottom
            },
          },
      };
      function validateEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      }
    
      const sendEmailCode = async (email) =>{

        if (!validateEmail(email)) {
          // Alert.alert('Invalid Email', 'please input a valid email');
          return '';
        } 
    
        console.log("User email address at forgot "+email)
          try {
            const response = await axios.post(`${baseUrl+"api/v1/authentication/user/forgot-password"}`, {
              email: email,
            });
            
            if(response.data?.success === true){
              console.log("Password reset request successful:", response.data);
              alert("User Reset code has been sent successfully, let the user check their email");
           
            }
            
            if(response?.data?.success === false){
              console.log("Issue with the email submitted ", response.data);
              alert(response?.data?.message); 
            }
    
          } catch (error) {
              console.error("Error message:", error.message);
              alert(error.message)
            
          }
            
        };
    return (
      <section >
        <div className='font-semibold text-2xl mb-4'>
          <h2 className='font-semibold text-gray-700'>Accounts</h2>
          <span className="text-xs text-gray-500">View accounts of registered users</span>
          <hr className="mt-2" />
        </div>
  
        {/* Search input for email */}
        <div className="flex justify-between items-center mb-4">
          <span className="text-gray-700">User Accounts</span>
          <input
            type="text"
            placeholder="Search by email"
            className="p-2 border rounded"
            value={searchEmail}
            onChange={e => setSearchEmail(e.target.value)}
          />
        </div>
  
        {/* DataTable Component */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <DataTable
            columns={columns}
            data={filteredAccounts}
            pagination
            highlightOnHover
            pointerOnHover
            responsive
            striped
            customStyles={customStyles}
          />
        </div>

        {showIndividualUser &&(
            <div>
                <div className="z-50 flex items-center justify-center fixed inset-0">
                    <div className="relative m-2 my-8 bg-gray-100 rounded-lg px-8 py-6 shadow-md"> 
                        {/* /color was put here  */}
                        <button
                            className="absolute top-0 right-0 p-4 text-red-500"
                            onClick={() => {
                            setShowIndividualUser(false);
                            }}
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-5 w-4"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div>

                            <div class="my-4 max-w-screen-md px-4 sm:mx-4 sm:rounded-xl sm:px-4 sm:py-4 md:mx-auto">
                                <div class="flex flex-col border-b py-4 sm:flex-row sm:items-start">
                                    <div class="shrink-0 mr-auto sm:py-3">
                                    <p class="font-medium">{individualUser?.role_id === 1 ? 'CUSTOMER' : 'PROVIDER'} ROLE</p>
                                    <p class="text-sm text-gray-600">Account details</p>
                                    </div>
                                    <button class="mr-2 hidden rounded-lg border-2 px-4 py-2 font-medium text-gray-500 sm:inline focus:outline-none focus:ring hover:bg-gray-200">Cancel</button>
                                    <button class="hidden rounded-lg border-2 border-transparent bg-blue-600 px-4 py-2 font-medium text-white sm:inline focus:outline-none focus:ring hover:bg-blue-700"
                                    onClick={()=>{
                                      console.log("clicking the button"+individualUser?.email)
                                      sendEmailCode(individualUser?.email)
                                    }}>
                                      Password Reset
                                    </button>
                                </div>
                                <div class="flex flex-col gap-4 border-b py-4 sm:flex-row">
                                    <p class="shrink-0 w-32 font-medium">Name</p>
                                    <input placeholder={individualUser?.first_name} class="mb-2 w-full rounded-md border bg-white px-2 py-2 outline-none ring-blue-600 sm:mr-4 sm:mb-0 focus:ring-1" />
                                    <input placeholder={individualUser?.last_name} class="w-full rounded-md border bg-white px-2 py-2 outline-none ring-blue-600 focus:ring-1" />
                                </div>
                                <div class="flex flex-col gap-4 border-b py-4 sm:flex-row">
                                    <p class="shrink-0 w-32 font-medium">Email</p>
                                    <input placeholder={individualUser?.email} class="w-full rounded-md border bg-white px-2 py-2 outline-none ring-blue-600 focus:ring-1" readOnly/>
                                </div>
                                <div class="flex flex-col gap-4 border-b py-4 sm:flex-row">
                                    <p class="shrink-0 w-32 font-medium">Phone Number</p>
                                    <input placeholder={individualUser?.phone_number} class="w-full rounded-md border bg-white px-2 py-2 outline-none ring-blue-600 focus:ring-1"/>
                                </div>
    
                            </div>

                        </div>
                        
                    </div>    
                </div>
            </div>
        )}

      </section>
    );
    
  };
  
  export default MyDataTable;