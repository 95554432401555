function ServicesPage() {
    return (
        <>
        <div className="relative z-10 pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[100px] bg-primary1 overflow-hidden" >
            <div className="container">
                <div className="flex flex-wrap items-center -mx-4">
                    <div className="w-full px-4">
                        <div className="text-center">
                        <h1 className="font-semibold text-white text-4xl">Our Services</h1>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        <section className=" bg-[#f3f4ffe4] pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] relative z-20  overflow-hidden " >
        
      <div className="container">
        

        <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-1/2 px-4">
                <div className=" single-faq w-full bg-[#36b6ff] border border-[#F3F4FE] rounded-lg p-5 sm:p-8 mb-8 wow fadeInUp " data-wow-delay=".1s " >
                    <div className="w-full">
                        <h4 className="text-base sm:text-lg font-semibold text-white">
                        Cooking Gas
                        </h4>
                        <p className="text-base text-white leading-relaxed py-3">
                        With a few clicks , you can get a re-fill or new cylinder within 30 min.
                        </p>
                </div>
            </div>
            
            <div className=" single-faq w-full bg-[#36b6ff] border border-[#F3F4FE] rounded-lg p-5 sm:p-8 mb-8 wow fadeInUp " data-wow-delay=".1s " >
              <div className="w-full">
                  <h4 className="text-base sm:text-lg font-semibold text-white">
                    Roadside Emergency Services
                  </h4>
                    <p className="text-base text-white leading-relaxed py-3">
                    Dont get stuck on the road. With our app, you can get help without hassle. We guarantee to get you help in the shortest time possible and the best deal available.
                    </p>
                </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 px-4">
          <div className=" single-faq w-full bg-[#36b6ff] border border-[#F3F4FE] rounded-lg p-5 sm:p-8 mb-8 wow fadeInUp " data-wow-delay=".1s " >
              <div className="w-full">
                  <h4 className="text-base sm:text-lg font-semibold text-white">
                  Fuel Service
                  </h4>
                    <p className="text-base text-white leading-relaxed py-3">
                    Find cheap gas around you, have it delivered on the road, or just go to the nearest affordable petrol station..
                    </p>
                </div>
            </div>
            <div className=" single-faq w-full bg-[#36b6ff] border border-[#F3F4FE] rounded-lg p-5 sm:p-8 mb-8 wow fadeInUp " data-wow-delay=".1s " >
              <div className="w-full">
                  <h4 className="text-base sm:text-lg font-semibold text-white">
                  Routine Repair Service
                  </h4>
                    <p className="text-base text-white leading-relaxed py-3">
                    If you need routine repair, you can count on the RideX App to get you the fastest service with the best deal available any time.
                    </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      
    </section>
    </>
    );
}

export default ServicesPage;