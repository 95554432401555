import React, { memo, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import { getUser } from "../../utils/HelperFunction";
import { baseUrl } from '../../services/api';

const user = getUser();
const authHeader = {
    'Content-Type': 'application/json',
    'Accept': 'application/json', 
    'Authorization': user ? `Bearer ${user.access_token}` : ''
};

const containerStyle = {
    width: '100%',
    height: '90svh'
};

function Maps() {
    const [markers, setMarkers] = useState([]);
    const [filteredMarkers, setFilteredMarkers] = useState([]); // State for filtered markers
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [responseMessage, setResponseMessage] = useState(false);  
    const [successMessage, setSuccessMessage] = useState(true);
    const [requestResponseMessage, setRequestResponseMessage] = useState('');
    const [loading, setLoading] = useState(true);  
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedService, setSelectedService] = useState('');  // State for selected service

    const center = {
        lat: latitude,
        lng: longitude
    };

    const setMarkerForProviders = useCallback((accounts) => {
        let providers = [];
        accounts.forEach((account) => {
            if (account?.role_id === 2 && account?.address?.coordinates[0] !== undefined) {
                providers.push({
                    lat: parseFloat(account?.address?.coordinates[0]),
                    lng: parseFloat(account?.address?.coordinates[1]),
                    name: account.first_name,
                    last_name: account.last_name,
                    service_id: account.service_id,
                    phoneNumber:account.phone_number
                });
            }
        });
        return providers;
    }, []);

    const fetchAccounts = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}api/v1/authentication/user/providers`, { headers: authHeader });
            const providers = setMarkerForProviders(response.data.data);

            setMarkers(providers);
            setFilteredMarkers(providers);  // Initially, show all markers
            setIsReady(true);
        } catch (error) {
            // console.error("Error fetching provider accounts:", error);
            setErrorMessage("Failed to load providers data");
        } finally {
            setLoading(false);
        }
    }, [setMarkerForProviders]);

    useEffect(() => {
        getLocation();
    }, []);

    useEffect(() => {
        if (latitude !== null && longitude !== null) {
            fetchAccounts();
        }
    }, [latitude, longitude, fetchAccounts]);

    // Filter markers based on selected service
    useEffect(() => {
        if (selectedService) {
            const filtered = markers.filter(marker => marker.service_id === parseInt(selectedService));
            setFilteredMarkers(filtered);
        } else {
            setFilteredMarkers(markers); // Show all markers if no filter is selected
        }
    }, [selectedService, markers]);

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    // console.error(error);
                    if (error.message === 'User denied Geolocation') {
                        setErrorMessage("Please enable your browser location");
                        setRequestResponseMessage("Please enable your browser location");
                        setResponseMessage(true);
                        setSuccessMessage(false);
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    const renderedImageOptions = (service_id) => {
        const imageOptions = {
            1: require('../../assets/svgs/repair.png'), //repair
            2: require('../../assets/svgs/fuel.jpg'), //fuel
            3: require('../../assets/svgs/human.png'), //medical
            4: require('../../assets/svgs/traffic-lights.png'), //traffic
            5: require('../../assets/svgs/gas.jpg'), //gas 
            6: require('../../assets/svgs/vehicle.png'), //vehicle
        };
        return imageOptions[service_id] || ""; 
    };

    function RenderMarker() {
        return (
            filteredMarkers.map((marker, index) => (
                <AdvancedMarker key={index} position={{ lat: marker.lat, lng: marker.lng }} onClick={()=>{
                    // console.log("clicking now "+JSON.stringify(marker))
                    displayInfo(marker)
                    }} >
                    <div style={{ backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: 8 }}>
                        <h2>{marker.name}</h2>
                        {/* <h2>{marker.phoneNumber}</h2> */}
                    </div>
                    <img src={renderedImageOptions(marker.service_id)} alt="User Icon" style={{ width: '25px', height: '25px' }} />
                </AdvancedMarker>
            ))
        );
    }

    const displayInfo =(item) =>{
        setRequestResponseMessage("Name: " + item.name + " " + item.last_name + "  " + " +" + item.phoneNumber);
                        setSuccessMessage(true);
                        setResponseMessage(true);
    }

    // Handle service filter change
    const handleServiceChange = (e) => {
        setSelectedService(e.target.value);
    };

    return (
        <section>
            <div className="font-semibold text-2xl">
                <h2 className="font-semibold text-gray-700">Providers on maps</h2>
                <span className="text-xs text-gray-500">View our providers on the map</span>
                <hr />
            </div>
            
            {/* Filter Dropdown */}
            <div style={{ margin: '10px 0' }}>
                <label htmlFor="serviceFilter" style={{paddingRight:30}}>Select to Filter by Service: </label>
                <select id="serviceFilter" value={selectedService} onChange={handleServiceChange} style={{marginBottom:10, marginTop:10}}>
                    <option value="">All Services</option>
                    <option value="1">REPAIR</option>
                    <option value="2">FUEL</option>
                    <option value="3">MEDICAL</option>
                    <option value="4">TRAFFIC</option>
                    <option value="5">GAS</option>
                    <option value="6">VEHICLE</option>
                </select>
            </div>
            
            <div>
                {!loading && isReady ? (
                    <APIProvider apiKey={'AIzaSyCcBdz1dGf3g3AlW1LgAu9xFImR-_z1uMU'}>
                        <Map
                            style={{ width: '100%', height: '100vh' }}
                            defaultCenter={center}
                            defaultZoom={13}
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                            mapId={'12344'}
                        >
                            <AdvancedMarker position={center}>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '50%',
                                        border: '2px solid red',
                                        backgroundColor: 'rgba(255,0,0,0.2)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img src={require('../../assets/svgs/final.png')} alt="User Icon" style={{ width: '35px', height: '35px' }} />
                                </div>
                            </AdvancedMarker>
                            <RenderMarker />
                        </Map>
                    </APIProvider>
                ) : (
                    <div>
                        {loading ? <p>Loading maps...</p> : <p>{errorMessage}</p>}
                    </div>
                )}
            </div>
            {responseMessage && (
                <div className='z-50 flex items-center justify-center fixed inset-0'>
                    <div className="relative m-2 my-8 max-w-md rounded-lg bg-gray-600 px-12 py-6 shadow-md">
                        <button className="absolute top-0 right-0 p-4 text-gray-400" onClick={() => { setResponseMessage(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <p className="relative mb-1 text-sm font-medium">
                            {successMessage ? (
                                <span className="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-green-400 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-3 w-3">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                </span>
                            ) : (
                                <span className="absolute -left-7 flex h-5 w-5 items-center justify-center rounded-xl bg-red-400 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-3 w-3">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                </span>
                            )}
                            {successMessage ? <span className="text-gray-50">Success!</span> : <span className="text-gray-50">Error!</span>}
                        </p>
                        <p className="text-sm text-gray-200">{requestResponseMessage}</p>
                    </div>
                </div>
            )}
        </section>
    );
}

export default memo(Maps);
