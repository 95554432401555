import { Link } from "react-router-dom";

function AboutPage() {
    return (
        <>
            <div className="relative z-10 pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[100px] bg-primary1 overflow-hidden">
                <div className="container">
                    <div className="flex flex-wrap items-center -mx-4">
                        <div className="w-full px-4">
                            <div className="text-center">
                                <h1 className="font-semibold text-white text-4xl">About MyRidex</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="about" className="pt-20 lg:pt-[120px] pb-20 lg:pb-[120px] bg-[#f3f4fe]">
                <div className="container">
                    <div className="bg-white wow fadeInUp" data-wow-delay=".2s">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full lg:w-1/2 px-4 flex flex-col justify-center items-start">
                                <div className="py-12 px-7 sm:px-12 md:p-16 lg:p-16 xl:p-[70px]">
                                    <span className="text-sm font-medium text-white py-2 px-5 bg-[#36b6ff] inline-block mb-5">
                                        About Us
                                    </span>
                                    <h2 className="font-bold text-3xl sm:text-4xl 2xl:text-[40px] sm:leading-snug text-dark mb-6">
                                        MyRideX Technologies
                                    </h2>
                                    <p className="text-base text-body-color mb-9 leading-relaxed" style={{ textAlign: 'justify' }}>
                                        At MyRideX Technologies, we are committed to providing fast and reliable on-demand services designed to meet your daily needs.
                                        Our innovative platform allows users to access essential services, from gas refills to roadside assistance, with just a few clicks.
                                        We ensure convenience and efficiency through our app, guaranteeing that help arrives swiftly when you need it most.
                                    </p>
                                    <p className="text-base text-body-color mb-9 leading-relaxed" style={{ textAlign: 'justify' }}>
                                        At MyRideX, we prioritize convenience, affordability, and customer satisfaction,
                                        driving us to continually improve our services and keep you moving without worry.
                                    </p>
                                    <Link to={'/services'} className="inline-flex items-center justify-center py-4 px-6 rounded text-white bg-[#36b6ff] text-base font-medium hover:bg-opacity-90 hover:shadow-lg transition duration-300 ease-in-out">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2 px-4 flex justify-center items-center">
                                <div className="relative inline-block z-10">
                                    <img
                                        src={require("../../assets/img/ridex-logo.png")}
                                        alt="RideX Logo"
                                        className="mx-auto h-32 w-32 md:h-48 md:w-48 lg:h-[450px] lg:w-[450px]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutPage;
